import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Link,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArchiveIcon from "@mui/icons-material/Archive";
import DateWithExpiration from "./DateWithExpiration/DateWithExpiration";
import StyledTableCell from "./Styled/StyledTableCell";
import ActionIconButton from "./ActionIconButton";
import { DEFAULT_STRING } from "../constants/defaultStrings";
import { Link as ButtonLink } from "react-router-dom";
import EditCompanyDialog from "./Dialogs/EditCompanyDialog";
import CompanyToolTipTitle from "./CompanyToolTipTitle";

const LicenseRow = ({
  index,
  license,
  onDownload,
  isLoading,
  toArchive,
  canEditHolderInfo,
  canEditCompanyInfo,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [license?.id]);

  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <>
      <TableRow>
        <StyledTableCell>
          <IconButton size="small" onClick={handleToggle}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{license.device.model}</StyledTableCell>
        <StyledTableCell>{license.device.serialNumber}</StyledTableCell>
        <StyledTableCell>
          {license.holder && (
            <Tooltip
              title={
                <>
                  <Box style={{ margin: "4px" }}>{`${
                    license.holder?.contactPerson || ""
                  }`}</Box>
                  <Box style={{ margin: "4px" }}>
                    {license.holder?.email || ""}
                  </Box>
                  <Box style={{ margin: "6px" }}>
                    {license.holder?.phoneNumber || ""}
                  </Box>
                  <Box style={{ margin: "4px" }}>
                    {license.holder?.comments || ""}
                  </Box>
                </>
              }
              placement="bottom-start"
            >
              {canEditHolderInfo ? (
                <Link
                  color="inherit"
                  target="_blank"
                  href={`/holder/${license.holder.id}`}
                  sx={{
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  {license.holder.companyName || DEFAULT_STRING.NO_NAME}
                </Link>
              ) : (
                <Box>
                  {license.holder.companyName || DEFAULT_STRING.NO_NAME}
                </Box>
              )}
            </Tooltip>
          )}
        </StyledTableCell>
        <StyledTableCell />
        <StyledTableCell align="right">
          <Button
            component={ButtonLink}
            to={`/history/${license.device.model}/${license.device.serialNumber}`}
            disabled={isLoading}
            variant="outlined"
            target="_blank"
            size="small"
            style={{
              height: "25px",
              width: "110px",
              textTransform: "none",
              color: "grey",
              borderColor: "grey",
            }}
          >
            View history
          </Button>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Button
            disabled={isLoading}
            variant="contained"
            size="small"
            style={{ height: "25px", width: "110px" }}
            onClick={() => onDownload(license.id)}
          >
            Download
          </Button>
        </StyledTableCell>
      </TableRow>
      {license.products?.map(
        (product, productIndex) =>
          open && (
            <TableRow>
              <StyledTableCell style={{ width: "34px" }} />
              <StyledTableCell>
                {product.name === "" ? DEFAULT_STRING.NO_NAME : product.name}
              </StyledTableCell>
              <StyledTableCell>
                {canEditCompanyInfo ? (
                  <EditCompanyDialog
                    companyName={product.creator?.companyName}
                    withTooltip={true}
                    tooltipTitle={<CompanyToolTipTitle product={product} />}
                  >
                    {product.creator?.companyName || DEFAULT_STRING.UNKNOWN}
                  </EditCompanyDialog>
                ) : (
                  <Tooltip title={<CompanyToolTipTitle product={product} />}>
                    <span>
                      {product?.creator?.companyName || DEFAULT_STRING.UNKNOWN}
                    </span>
                  </Tooltip>
                )}
              </StyledTableCell>
              <StyledTableCell>
                <Box display="flex" flexDirection="row">
                  <InputLabel
                    style={{
                      fontSize: "12px",
                      marginRight: "8px",
                      marginTop: "2px",
                    }}
                  >
                    Issue date
                  </InputLabel>
                  {new Date(
                    product.creationTime.replace("Z", "")
                  ).toLocaleDateString("ru-RU")}
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box display="flex" flexDirection="row" height="24px">
                  <InputLabel
                    style={{
                      fontSize: "12px",
                      marginRight: "8px",
                      marginTop: "4px",
                    }}
                  >
                    Valid till
                  </InputLabel>
                  <DateWithExpiration
                    date={new Date(product.validTill.replace("Z", ""))}
                  />
                </Box>
              </StyledTableCell>
              <StyledTableCell />
              <StyledTableCell>
                <Box textAlign="center">
                  <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    style={{ height: "25px", width: "110px", margin: "5px 0px" }}
                    onClick={() => toArchive(product.id)}
                  >
                    TO ARCHIVE
                  </Button>
                </Box>
              </StyledTableCell>
            </TableRow>
          )
      )}
    </>
  );
};

export default LicenseRow;
